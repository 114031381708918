
.dropzone-container {
    width: 300px;
    height: 250px;
    border: 1px rgb(209, 209, 209) solid;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.dropzone-title {
    font-size: 16px;
    font-weight: 600;
    margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 12px;
}

.dropzone-image-container {
    width: 284px;
    height: 200px;
    border: 1px rgb(209, 209, 209) solid;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.dropzone-image {
    width: 280px;
    height: 196px;
    margin: 2px;
    object-fit: contain;
}

.dropzone-image-container:hover {
    border: 1px rgb(166, 166, 166) solid;
}

.dropzone-image-placeholder {
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
}
