.teacherBtn {
    text-align: right;
}

.teacherBtn button {
    margin-right: 10px;

}

.teacher-Header .teacherDropdown {
    margin-right: 10px;
}
  