.create-form {
    height: calc(100vh - 144px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.create-form::-webkit-scrollbar {
    display: none;
}

.create-form-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    padding-bottom: 16px;
}

.create-form-column {
    width: calc(50% - 16px);
    height: 100%;
}

.create-form-input-row {
    display: flex;
    flex-direction: row;
}

.submit-button {
    border: 1px grey solid;
    outline: none;
    border-radius: 4px;
    width: fit-content;
    padding-left: 16px;
    padding-right: 16px;
    height: 32px;
    font-size: 16px;
    margin-top: 8px;
}

.submit-button:hover {
    filter: brightness(0.95);
}

.input-size-container {
    width: 300px;
    margin-right: 16px;

}

.input-size-container-large {
    width: 616px;
    margin-right: 16px;
}

.loading-container {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    margin-top: 32px;
}


@media screen and (max-width: 1700px) {
    .input-size-container-large {
        width: 500px;
    }
}

@media screen and (max-width: 1400px) {
    .input-size-container-large {
        width: 350px;
    }
}