.couses-list-title-container {
    border-bottom: none !important;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 0;
}

.couses-list-title-container-desc {
    border-bottom: none !important;
    font-size: 15px;
    text-align: center;
}

.img-container {
    position: relative;
}

.img-container img {
    max-width: 100%;
}

.img-container .img-container-inner {
    position: absolute;
    right: 0;
    bottom: 10px;
    padding-left: 10px;
    overflow: hidden;
}

.img-container .img-container-inner span {
    background: green;
    color: #fff;
    padding: 2px 12px;
}

.img-container .img-container-inner span::before {
    content: '';
    height: 16px;
    width: 16px;
    background: green;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    left: 4px;
    bottom: -7px;
}

.img-container .img-container-inner span::after {
    content: '';
    height: 16px;
    width: 16px;
    background: green;
    display: block;
    position: absolute;
    transform: rotate(45deg);
    left: 4px;
    top: -7px;
}

.container .row .card-col {
    /* margin-top: 35px;
    margin-left: 35px;
    margin-right: 35px; */
    margin: 35px 35px 35px 50px;
}

.course {
    justify-content: center;
    display: flex;
}

.course a {
    color: rgba(18, 21, 78, 0.7);
}

.course .card-title {
    height: 34px;
    overflow: hidden;
}