:root {
    --dark-green: rgb(5, 169, 127);
    --light-green: rgb(229, 245, 234);
}

.container-teacher-subscribe {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-content: center;
    justify-content: center;
}

/* Course header section */
.course-header {
    background: rgb(247, 253, 251);
    width: 100%;
    height: 50vh;
    position: relative;
}

.course-header .course-title {
    font-weight: bold;
    text-align: center;
    color: black;
    font-family: 'Share Tech', sans-serif;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: 100%;
    height: 40vh;
    text-shadow: 8px 8px 10px #0000008c;
    position: relative;
    z-index: 2;
}

.course-header ul {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.course-header ul li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(170, 213, 254, 0.82);
    animation: animate 25s linear infinite;
    bottom: -50%;
}

.course-header ul li:nth-child(1) {
    right: 20%;
    bottom: 20px;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
    background: rgb(248, 233, 221);
}


.course-header ul li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 19s;

}

.course-header ul li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 8s;
}

.course-header ul li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 14s;
    animation-duration: 18s;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.course-header span {
    position: absolute;
    display: block;
    content: "";
    left: 3%;
    bottom: -50%;
    width: 350px;
    height: 350px;
    background: #f9fcd7;
    border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
    will-change: border-radius, transform, opacity;
    animation: sliderShape 5s linear infinite;
    -webkit-animation: sliderShape 5s linear infinite;
    /* z-index: 2; */
}

@keyframes sliderShape {
    0%, 100% {
        border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    34% {
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
        transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
    }
    50% {
        transform: translate3d(0, 0, 0) rotateZ(0.01deg);
    }
    67% {
        border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    }
}

/* Course details */
.container-teacher-subscribe .course .course-details {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
    margin-bottom: 2rem;
    background: rgb(34, 34, 34);
    font-size: 1rem;
    position: relative;
}

.container-teacher-subscribe .course .course-details div {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    color: white;
}

.container-teacher-subscribe .course .course-thumbnail {
    width: clamp(400px, 45vw, 600px);
    height: auto;
}


.t-registration-form {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    gap: 2rem;
}

/* Download and Upload section */
.t-registration-form .upload-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.t-registration-form .upload-section label {
    display: flex;
    flex-direction: column;
    flex: 0 1 300px;
    gap: 0.5rem;
}

.t-registration-form .upload-section label span {
    font-size: 1rem;
    font-weight: 500;
}

.t-registration-form .upload-section label .download-button {
    font-size: 0.9rem;
    padding: 0.5rem;
    display: flex;
    gap: 0.6rem;
    justify-content: center;
    align-items: center;
    background: #1e5303;
    color: white;
    transition: all ease-in-out 300ms;
    border: 2px solid #4a7e2f;
    border-radius: 9px;
}

.t-registration-form .upload-section label .download-button:hover {
    opacity: 0.9;
    border-radius: 20px;
    background: #153b02;
}

.t-registration-form .upload-section .upload-button {
    /* width: 50%;
    margin: 0 auto; */
    font-size: 1rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    color: white;
    cursor: pointer;
    transition: all ease-in-out 200ms;
    background-color: #3f87fa;
    border: 2px solid #9ec3ff;
    border-radius: 9px;
}

.t-registration-form .upload-section .upload-button:hover {
    opacity: 0.9;
    border-radius: 20px;
    background-color: #3673ce;
    border-color: #729fe7;
}

/* alert section */
.upload-section .alert-container {
    background: var(--light-green);
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 1rem;
    padding: 0.5rem;
    padding-bottom: 1rem;
    font-weight: bold;
    border-radius: 0.4rem;
}

.upload-section .alert-container .alert-icon {
    width: 37px;
    height: 35px;
    background: url("../../../assets/components/icons/linearicons/pdfIcon.png");
    background-size: cover;
    background-repeat: none;
    font-weight: bold;
}

.alert-container .alert-right-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.4rem;
}

.alert-container .alert-text-and-close-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.6rem;
    color: rgb(6, 74, 6);
}

.alert-container .close-button {
    font-size: 1.3rem;
    cursor: pointer;
}

.t-registration-form .registration-form-button {
    width: 30%;
    max-width: 300px;
    text-align: center;
    color: white;
    background: rgb(5, 169, 127);
    border: 1px grey solid;
    outline: none;
    border-radius: 4px;
    padding: 3px 24px;
    font-size: 16px;
    margin: 0 auto;
    transition: all ease-in-out 300ms;
}

.t-registration-form .registration-form-button:hover {
    opacity: 0.9;
    border-radius: 14px;
}

@media only screen and (max-width: 700px) {
    .course .course-details {
        flex-direction: column-reverse;
    }

    .course .course-thumbnail {
        max-width: 400px;
        margin: 0 auto;
    }

    .t-registration-form .upload-section {
        flex-direction: column;
    }

    .t-registration-form .upload-section label {
        width: 60%;
        min-width: 200px;
        flex: 0 1 40px;
    }
}

