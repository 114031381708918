.lp-courses-bar {
    display: flex;
    float: right;
    margin-bottom: 40px;
}

.lp-courses-bar .search-courses {
    position: relative;
    width: 306px;
    max-width: 100%;
    margin-right: 20px;
    line-height: 1;
    flex: 1;
}

.lp-courses-bar .search-courses input[type=text] {
    width: 100%;
    padding: 0 30px 10px 0;
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    background: #fff;
    font-size: 14px;
    line-height: 1;
}

.lp-courses-bar .search-courses button {
    position: absolute;
    top: 50%;
    right: 0;
    width: 30px;
    height: 30px;
    padding: 0;
    border: 0;
    color: #333 !important;
    background: transparent !important;
    font-size: 16px;
    line-height: 1;
    transform: translate(0, -50%);
}

.learn-press-courses[data-layout=list] .course {
    display: flex;
    width: 100%;
    border: 1px solid rgba(204, 204, 204, 0.3);
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 0 0 40px 0;
    box-shadow: none;
    list-style: none;
}

.learn-press-courses[data-layout=list] .course-item {
    flex-direction: row;
    width: 100%;
    border: unset;
    display: flex;
    transition: all .3s;
}

.learn-press-courses[data-layout=list] .course-wrap-thumbnail {
    width: 35%;
}

.course-wrap-thumbnail {
    overflow: hidden;
    position: relative;
}

.learn-press-courses[data-layout=list] .course-wrap-meta {
    display: flex;
    padding-top: 10px;
    padding-bottom: 15px;
    color: #333;
    font-size: 14px;
    font-weight: 400;
}

.learn-press-courses[data-layout=list] .course-content {
    width: 65%;
}

.learn-press-courses[data-layout=list] .course-content {
    padding: 10px 25px;
}

.course-content .course-info .course-price .price {
    color: #333;
    font-size: 16px;
    font-weight: 600;
}

.course-content .course-categories {
    float: left;
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
}

.learn-press-courses[data-layout=list] .course-content .course-categories a {
    position: relative;
    padding: 2px 20px 2px 15px;
    -webkit-clip-path: polygon(0 0, 100% 0, 88% 50%, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 88% 50%, 100% 100%, 0 100%);
    line-height: 1.3em;
}

.course-content .course-categories a:first-child {
    display: block;
    margin-left: 0;
    padding: 2px 16px;
    background: #05a97f;
}

.course-content .course-categories a {
    display: inline-block;
    display: none;
    margin: 0 10px;
    color: #fff;
    text-decoration: none;
}

.course-content .course-categories, .course-content .course-tags {
    display: block;
    font-size: 14px;
    font-weight: 300;
}

.learn-press-courses .course .course-item .course-content .course-instructor {
    font-weight: 400;
}

.course-item .course-instructor {
    float: left;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 300;
    line-height: 25px;
    text-transform: capitalize;
}

.learn-press-courses[data-layout=list] .course-content .course-permalink {
    height: 25px;
    margin-bottom: 5px;
    -webkit-line-clamp: 1;
}

.course-content .course-permalink {
    display: -webkit-box;
    clear: both;
    overflow: hidden;
    position: relative;
    border: 0;
    color: #fff;
    text-decoration: none;
    text-transform: none;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.learn-press-courses[data-layout=list] .course-title {
    font-size: 18px;
}

.course-title {
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.learn-press-courses .course .course-item .course-content .course-info {
    font-weight: 500;
}

.course-content .course-info {
    color: #666;
    font-size: 14px;
    font-weight: 300;
}

.learn-press-courses .course .course-item .course-content .course-excerpt {
    font-weight: 400;
}

.course-content .course-excerpt {
    display: -webkit-box;
    overflow: hidden;
    margin-bottom: 20px;
    color: #666;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.learn-press-courses[data-layout=list] .course-content .course-footer {
    float: left;
    margin-right: 20px;
}

.learn-press-courses[data-layout=list] .course-content .course-readmore {
    float: right;
    position: relative;
    right: 4px;
}

.learn-press-courses[data-layout=list] .course-content .course-readmore a {
    padding: 9px 24px;
    border-radius: 3px;
    color: #fff;
    background: #05a97f;
    font-weight: 400;
    line-height: 1;
    transition: all .3s;
    display: block;
}