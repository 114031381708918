.userBtn {
    text-align: right;
}

.userBtn button {
    margin-right: 10px;
}

.user-Header .userDropdown {
    margin-right: 10px;
}
