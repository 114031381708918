.couses-list-title-container {
    width: 100%;
    height: fit-content;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 22px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid;
    margin-bottom: 32px;
}

.courses-table {
    width: 100%;
    max-height: 400px !important;
    overflow-y: auto;
}

.courses-table-row {
    border-bottom: 1px solid;
    height: 40px;
}

.courses-table-body-row {
    border-bottom: 1px solid;
    height: 66px;
    cursor: pointer;
}

.courses-table-body-row:hover {
    background-color: rgb(225, 225, 225);
}

.courses-table-head-cell {
    font-size: 16px;
    font-weight: 700;
    padding-left: 4px;
    text-align: center;
}

.courses-table-body-image-cell {
    display: flex;
}

.courses-table-body-thumbnail-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 12px;
}

.courses-table-body-cell {
    padding-left: 6px;
    font-size: 16px;
    font-weight: 500;
}

.body-cell-1 {
    max-width: 50px !important;
}

.body-cell-2 {
}

.body-cell-3 {
    text-align: center;
}

.body-cell-4 {
    text-align: center;
}

.body-cell-5 {
    min-width: 180px;
    text-align: center;
}

.body-cell-6 {

}

.body-cell-7 {

}

.body-cell-8 {
    width: 80px;
    text-align: center;
}

.body-cell-9 {
    width: 100px;
    text-align: center;
}

.body-cell-10 {
    width: 70px;
    text-align: center;
}

.body-cell-11 {
    min-width: 180px;
    position: relative;
}

.button-container {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-bottom: 16px;
}

.create-button {
    border: 1px solid;
    cursor: pointer;
    font-size: 16px;
    padding: 0 16px;
}

.create-button:hover {
    filter: brightness(0.95);
}