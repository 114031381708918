.course-screen {
    display: flex;
    justify-content: center;
}

.layout-column {
    width: 50%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 32px;
}

.thumbnail-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.abstract-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 32px;
}

.abstract-title {
}

.abstract-text {
}

.data-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
}

.bold-text {
    font-size: 18px;
    font-weight: 700;
}

.text {
    font-size: 18px;
    font-weight: 400;
}

.loading-container {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    margin-top: 64px;
}