
.rich-text-container {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
}

.title-input {
    outline: none;
    border-radius: 4px;
    height: 32px;
    width: 100%;
    border: 1px rgb(210, 210, 210) solid;
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 1rem;
    font-size: 18px;
    font-weight: 600;
}
